<template>
  <div class="page-list-af bg-main-af">
    <div class="box-top" id="top">
      <div class="fixed">
      <div class="box-tab">
        <p v-for="(item,idx) in tabs" :key="idx"
          :class="item.id==curTab ? 'sel' : ''"
           @click="onChangeTab(item.id)"
           > <i></i>{{item.title}} </p>
      </div>
      <div class="box-filter" @click="showDrawer=true">
        <img src="@/assets/af_i_search.png" />
        <el-input v-model="ruleForm.name" readonly placeholder="请输入搜索内容">
        </el-input>
        <span class="btn"><img class="ic" src="@/assets/af_i_filter.png" />筛选</span>
      </div>
      </div>
    </div>

    <div v-if="isEmpty" class="box-empty">
      <img src="@/assets/empty.png" />
    </div>
    <div v-else class="box-list" :style="{height: pageHeight + 'px'}" @scroll="handleScroll">
      <div class="item" v-for="(item, index) in dataList" :key="index"
        @click="jumpTo(item)">
        <p><span class="card">{{item.car_plate}}</span></p>
        <p class="name">{{item.order_type}} {{item.order_no}}</p>
        <p class="time">{{item.in_time}}</p>
        <p class="score">
        <strong v-if="item.score_status=='评分中'" style="color: #FAC15C">{{item.score_status}}</strong>
        <strong v-else-if="item.score_status==('无录音匹配'||'录音超长未处理')" style="color:#ED5A54">{{item.score_status}}</strong>
        <strong v-else style="color:#0070C0; text-align: center; font-size: 0.6rem; display: block;">
          <span style="font-size: 1.2rem; display: block;">{{item.score}}</span>评分
        </strong>
        </p>
      </div>

      <div v-if="showMore" style="text-align: center; padding: 1rem 0; color: #999">加载中... </div>
    </div>

    <el-drawer
      class="pop-search-af"
      title="筛选"
      direction="btt"
      size="90%"
      :visible.sync="showDrawer"
      >
      <el-form :model="ruleForm"
        status-icon ref="ruleForm"
        label-position="top" 
        class="box-form"
        >
        <el-form-item label="车牌号码">
          <el-input type="text" v-model="ruleForm.licence_number" placeholder="请输入车牌号码"></el-input>
        </el-form-item>
        <el-form-item label="车架号">
          <el-input type="text" v-model="ruleForm.car_frame" placeholder="请输入车架号">
          </el-input>
        </el-form-item>
        <el-form-item label="车主姓名">
          <el-input type="text" v-model="ruleForm.car_owners" placeholder="请输入车主姓名"></el-input>
        </el-form-item>
        <el-form-item label="标签名称">
          <el-input type="text" v-model="ruleForm.tag_name" placeholder="请输入标签名称">
          </el-input>
        </el-form-item>
        <el-form-item label="主工单号">
          <el-input type="text" v-model="ruleForm.order_no" placeholder="请输入主工单号"></el-input>
        </el-form-item>
        <el-form-item label="入场日期">
          <div class="box-date">
            <img class="ic" src="@/assets/af_i_date_d.png" />
            <el-date-picker
              @change="startChange"
              v-model="ruleForm.start_in_time"
              type="date"
              align="center"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="开始日期">
            </el-date-picker>
            -
            <el-date-picker
              @change="endChange"
              v-model="ruleForm.end_in_time"
              type="date"
              align="center"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="开单日期" v-if="curTab != 1">
          <div class="box-date">
            <img class="ic" src="@/assets/af_i_date_d.png" />
            <el-date-picker
              @change="startChange"
              v-model="ruleForm.start_date"
              type="date"
              align="center"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="开始日期">
            </el-date-picker>
            -
            <el-date-picker
              @change="endChange"
              v-model="ruleForm.end_date"
              type="date"
              align="center"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="pop-btn">
          <el-button @click="ruleForm={}">重置</el-button>
          <el-button type="primary" @click="onSearch(1)">提交</el-button>
        </el-form-item>
      </el-form>
      </el-drawer>

    <NavBar tab="服务接待"></NavBar>
  </div>
</template>

<script>
import { postForm, getEntryStateOtps } from '@/api'
import NavBar from '@/components/afNavbar.vue'
import {getRole} from '@/utils/getRole'
import moment from 'moment';


const yesterday = moment().clone().add(-1, 'day').format('YYYY-MM-DD');
export default {
  data() {
      return {
        ruleForm: {
        },
        curTab: 1,
        cardId: '',
        tabs: [
          {title: '当日接待', id: 1},
          {title: '历史接待', id: 2},
        ],
        types: ['展厅接待','DCC呼入','DCC呼出'],
        states: ['接待中','已完成'],
        businessOpts: ['展厅接待','试乘试驾'],
        sounds: ['未上传','已上传'],
        entryStateOpts: [],
        sn: '',
        showDrawer: false,
        showPop: false,
        isEmpty: false,
        pageNum: 1,
        pageSize: 20,
        dataList: [],
        showMore: false,
        online: false,
        electricity: 0,
        pageHeight: 0,
        startOpts: {
          disabledDate: (time) => {
              return new Date(time) > new Date(this.end_date || '')
          }
        },
        endOpts: {
          disabledDate: (time) => {
              return new Date(time) < new Date(this.start_date || '')
          }
        }
      }
  },
  components:{
    NavBar,
  },
  methods : {
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = event.target;
      const dist = 5;
      if (scrollHeight - (scrollTop + clientHeight) < dist && scrollWidth - (scrollLeft + clientWidth) < dist) {
        this.onSearch();
      }
    },
    jumpTo(item) {
      const {id} = item;
      this.$router.push({name: 'afReceptionShow', query: {id}});
    },
    closeDrawer() {
      this.ruleForm = {};
      this.showDrawer = false;
    },
    onChangeTab(id) {
      if (id == this.curTab) {
        return;
      }
      this.isEmpty = false;
      this.ruleForm = {
        ...this.ruleForm,
        start_date: null,
        end_date: null,
      }
      this.$router.replace({name: 'afReceptionList', query: {id}});
      this.onSearch(1);
    },
    endChange(v) {
      this.end_date = v;
    },
    startChange(v) {
      this.start_date = v;
    },
    onSearch(num) {
      const {id} = this.$router.currentRoute.query || {};
      id && (this.curTab = id);

      const pageNum = num || this.pageNum;
      const {pageSize, curTab, ruleForm} = this;

      if (curTab == 1) {
        ruleForm.start_date = yesterday;
        ruleForm.end_date = yesterday;
      }
      if (this.lock) {
        return;
      }
      this.lock = true;
      postForm('/h5/sa_seller/lists', {
        ...ruleForm,
        pageSize,
        pageNum
      }).then(res => {
        if (res.status === 1) {
          this.lock = false;
          if (!res.data) {
            return;
          }

          if (pageNum === 1) {
            this.dataList = res.data;
          } else {
            this.dataList = [...this.dataList, ...res.data];
          }

          this.isEmpty = !this.dataList.length;
          this.lockNum =  Number(res.pageNum);
          this.pageNum = Number(res.pageNum) + 1;
          const total = Number(res.total);
          this.showMore = total > this.dataList.length;

          this.showDrawer = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleResize() {
      const top = document.getElementById('top').clientHeight;
      this.pageHeight = document.documentElement.clientHeight - top - 50;
    },
    
  },
  mounted() {
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    if (getRole().isVolvo) {
      this.tabs = [
          {title: '上一日接待', id: 1},
          {title: '历史接待', id: 2},
        ]
    }
    this.onSearch();
  }
    
}
</script>

<style lang="less">

.page-list-af {
.box-form {
  padding: 0.5rem 1rem 2.5rem 1rem;

  input::placeholder{
    color: #999;
    font-size: 0.7rem;
  } 
  .el-form-item {
    margin-bottom: 0.8rem;
  }
  .el-form-item__label {
    font-weight: 400;
    line-height: 1.1;
  }
  .el-form-item__content {
    line-height: 2.5rem;
    height: 2.5rem;
  }

  .box-date {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;

    input.el-input__inner {
      text-align: center !important;
      border: 0;
    }
    .ic {
      width: 1rem;
      height: 1rem;
    }
    .el-icon-date {
    }
  }
  .group-btn {
    justify-content: flex-start;
  }
}

.pop-type {
  .el-dialog {
    border-radius: 0.5rem;
  }
  .el-dialog__body {
  }

  .box-type {
    li {
      background-color: #F5F5F5;
      border-radius: 5px;
      margin-bottom: 0.5rem;
      padding: 0.5rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        font-size: 0.8rem;
        font-weight: 500;
      }
      .intro {
        font-size: 0.6rem;
        font-weight: 400;
        opacity: 36%;
      }
    }
  }
}
.pop-search-af {
  .el-drawer {
  }
  .el-drawer__header {
    font-size: 1rem;
    color: #000;
  }

  .pop-btn {
    border-top: 1px solid #EFEFEF;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;

    .el-form-item__content {
      position: relative;
      display: flex;
      line-height: 2.5rem;
      height: 2.5rem;

      button {
        flex: 1;
        border: 0;
        border-radius: 0;
        line-height: 2rem;
        padding: 0;
      }
      .el-button--primary {
        background: #0070C0;
      }

    }
  }
}


.box-top {
  height: 6rem;
  .fixed {
    max-width: 750px;
    width: 100%;
    z-index: 1000;
    background: url('~@/assets/af_bg_main.png') top center no-repeat;
    background-size: 100% auto;
    padding: 0.5rem 0.8rem;
    position: fixed;
    top: 0;
  }

}
.box-tab {
  display: flex;
  justify-content: space-between;
  p {
    display: flex;
    align-items: center;
    max-width: 48%;
    flex: 1;
    background: #D6D7DB;
    border-radius: 1.5rem;
    height: 2.25rem;
    justify-content: center;
    font-size: 0.8rem;
    margin: 0;
    i {
      display: inline-block;
      margin-right: 0.5rem;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      height: 1rem;
      width: 1rem;
    }
    &:nth-child(1) i{
      background-image: url('~@/assets/af_i_today.png');
    }
    &:nth-child(2) i{
      background-image: url('~@/assets/af_i_history.png');
    }

    &.sel {
      color: #fff;
      background: #0070C0;
    }
    &:nth-child(1).sel i{
      background-image: url('~@/assets/af_i_today_sel.png');
    }
    &:nth-child(2).sel i{
      background-image: url('~@/assets/af_i_history_sel.png');
    }
  }
}
.box-filter {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  background: transparent;
  border-bottom: 1px solid #D9D9D9;
  img {
    width: 1.2rem;
    height: 1.2rem;
  }
  input {
    flex: 1;
    border: 0;
    line-height: 2rem;
    height: 2rem;
    background: transparent;
    padding: 0 0.25rem;
  }
  input::placeholder{
    color: #666;
    font-size: 0.7rem;
  } 
  .btn {
    display: inline-block;
    font-size: 0.7rem;
    width: 4rem;
    border-left: 1px solid #D9D9D9;
    text-align: right;
    margin: 0.4rem 0;
    display: flex;
    align-items: center;

    img {
      height: 0.8rem;
    }
  }
}

.box-empty {
  padding-top: 4rem;
  text-align: center;
  img {
    width: 13rem;
  }
}
.box-list {
  padding: 0 0.75rem 1rem 0.75rem;
  overflow: scroll;
  .item {
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0.5rem 0 0.5rem 0.5rem;

    p {
      margin: 0;
      margin-bottom: 0.3rem;
    }
    a{
      display: block;
    }
    .card {
      background: #0070C0;
      border-radius: 0.1rem;
      color: #fff;
      font-size: 0.8rem;
      display: inline-block;
      padding: 0.3rem 1rem;
    }
    .name {
      font-size: 0.7rem;
      color: #000;
    }
    .time{
      color: #999;
      font-weight: 400;
      font-size: 0.6rem;
    }
    .score {
      font-size: 0.7rem;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      strong {
        font-weight: normal;
      }

      &.green {
        color: #11C16F;
      }
      &.yellow {
        color: #FAC15C;
      }
      &.blue {
        color: #377EEA;
      }
      &.red {
        color: #ED5A54;
      }
    }
  }
}
.el-select .el-input {
  width: 5rem;
}
.input-with-select .el-input-group__prepend, .el-input-group__append {
  background-color: #fff;
}

}
</style>
